// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-after-school-programs-tsx": () => import("./../src/pages/after-school-programs.tsx" /* webpackChunkName: "component---src-pages-after-school-programs-tsx" */),
  "component---src-pages-contact-cortiz-fitness-tsx": () => import("./../src/pages/contact-cortiz-fitness.tsx" /* webpackChunkName: "component---src-pages-contact-cortiz-fitness-tsx" */),
  "component---src-pages-contact-fit-kidz-4-fun-tsx": () => import("./../src/pages/contact-fit-kidz-4-fun.tsx" /* webpackChunkName: "component---src-pages-contact-fit-kidz-4-fun-tsx" */),
  "component---src-pages-cortiz-fitness-home-tsx": () => import("./../src/pages/cortiz-fitness-home.tsx" /* webpackChunkName: "component---src-pages-cortiz-fitness-home-tsx" */),
  "component---src-pages-cortiz-fitness-pricing-tsx": () => import("./../src/pages/cortiz-fitness-pricing.tsx" /* webpackChunkName: "component---src-pages-cortiz-fitness-pricing-tsx" */),
  "component---src-pages-cortiz-fitness-sign-up-tsx": () => import("./../src/pages/cortiz-fitness-sign-up.tsx" /* webpackChunkName: "component---src-pages-cortiz-fitness-sign-up-tsx" */),
  "component---src-pages-fit-kidz-4-fun-registration-tsx": () => import("./../src/pages/fit-kidz-4-fun-registration.tsx" /* webpackChunkName: "component---src-pages-fit-kidz-4-fun-registration-tsx" */),
  "component---src-pages-fit-kidz-4-fun-tsx": () => import("./../src/pages/fit-kidz-4-fun.tsx" /* webpackChunkName: "component---src-pages-fit-kidz-4-fun-tsx" */),
  "component---src-pages-fit-kidz-plans-tsx": () => import("./../src/pages/fit-kidz-plans.tsx" /* webpackChunkName: "component---src-pages-fit-kidz-plans-tsx" */),
  "component---src-pages-gym-location-tsx": () => import("./../src/pages/gym-location.tsx" /* webpackChunkName: "component---src-pages-gym-location-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-at-fit-kidz-4-fun-tsx": () => import("./../src/pages/jobs-at-fit-kidz-4-fun.tsx" /* webpackChunkName: "component---src-pages-jobs-at-fit-kidz-4-fun-tsx" */),
  "component---src-pages-meet-the-team-tsx": () => import("./../src/pages/meet-the-team.tsx" /* webpackChunkName: "component---src-pages-meet-the-team-tsx" */),
  "component---src-pages-participating-schools-tsx": () => import("./../src/pages/participating-schools.tsx" /* webpackChunkName: "component---src-pages-participating-schools-tsx" */),
  "component---src-pages-summer-camp-tsx": () => import("./../src/pages/summer-camp.tsx" /* webpackChunkName: "component---src-pages-summer-camp-tsx" */)
}

